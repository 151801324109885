<template>
  <v-app>
    <v-container>
      <div v-if="loading" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>
      <v-card-text v-else>
        <p class="account-text-header">
          Имя
        </p>
        <v-text-field
          v-model="warehouse.name"
          outlined
          required
          label="* Название"
          :rules="nonEmptyRules"
        />
        <v-text-field
          v-model="warehouse.city"
          outlined
          required
          label="* Город"
          :rules="nonEmptyRules"
        />
        <v-select
          v-model="warehouse.company_id"
          item-text="name"
          item-value="id"
          :items="companies"
          outlined
          required
          persistent-hint
          single-line
          prepend-inner-icon="mdi-account-group"
          label="* Компания"
        />
        <v-btn
          text
          outlined
          color="dark"
          @click="saveWarehouse"
        >
          Сохранить
        </v-btn>
      </v-card-text>
    </v-container>
  </v-app>
</template>

<script>
import account from "@/api/account";

export default {
  name: "WarehouseForm",
  data() {
    return {
      loading: false,
      warehouse: {
        name: "",
        city: "",
      },
      companies: [],
    };
  },
  created() {
    this.loading = true;
    this.getWarehouseInfo();
    this.getCompaniesList();
    this.loading = false;
  },
  methods: {
    getCompaniesList() {
      return account.getCompanyList().then((response) => {
        this.companies = response.data;
      });
    },
    getWarehouseInfo: async function () {
      const warehouseId = this.$route.params.id;
      if (!warehouseId) {
        return;
      }
      try {
        const response = await account.getWarehouse(warehouseId);
        this.warehouse = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    saveWarehouse: async function () {
      if (!this.validateUserInfo()) {
        return;
      }
      if (this.warehouse.id) {
        await this.updateWarehouse();
      } else {
        await this.createWarehouse();
      }
    },
    validateUserInfo: function () {
      if (this.warehouse.name) {
        return true;
      }
      this.$store.dispatch("alertError", "Пожалуйста, заполните все обязательные поля");
      return false;
    },
    updateWarehouse: async function () {
      let warehouse = JSON.parse(JSON.stringify(this.warehouse));
      warehouse.company = this.warehouse.company_id;
      const response = await account.changeWarehouseInfo(warehouse);
      this.warehouse = response.data;
      this.$store.dispatch("alertSuccess", "Информация о пользователе успешно сохранена");
      return this.$router.push({name: 'WarehouseList'});
    },
    createWarehouse: async function () {
      let warehouse = JSON.parse(JSON.stringify(this.warehouse));
      warehouse.company = this.warehouse.company_id;
      const response = await account.createWarehouseInfo(warehouse);
      this.warehouse = response.data;
      this.$store.dispatch("alertSuccess", "Склад успешно создан");
      return this.$router.push({name: 'WarehouseList'});
    },
  },
};
</script>

<style scoped>
</style>
